import gql from "graphql-tag";

export const AI_SUBSCRIPTION = gql`
subscription aiResponse($subscriptionId: String!) {
    aiResponse(subscriptionId:$subscriptionId) {
        messageId
        stage
        step
        content
        contentType
        subscriptionId
    }
}
`;