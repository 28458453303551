<template>
    <div class="border border-gray-200 shadow-md rounded px-8 pt-6 pb-8 mb-4 grid grid-cols-2">
        <div class="m-2" :class="{ 'error-form-field': errorFields.includes('model') && formSubmited }">
            <label class="block">Model *</label>
            <select v-model="vModels['model'].value" class="w-full">
                <option value="null" selected>select an ai model</option>
                <option v-for="model in dropdowns.aiModels" :key="model" :value="model">{{model}}</option>
            </select>   
        </div>
        <div class="m-2" :class="{ 'error-form-field': errorFields.includes('paperContent') && formSubmited }">
            <label class="block">Paper Content * <small>(%)</small></label>
            <RangeInput v-model="vModels['paperContent'].value" :min="0" :max="100"/>
        </div>
        <div class="m-2" :class="{ 'error-form-field': errorFields.includes('totalGsm') && formSubmited }">
            <label class="block">Total GSM <small>(g/m2)</small></label>
            <RangeInput v-model="vModels['totalGsm'].value" :min="0"/>
        </div>
        <div class="m-2" :class="{ 'error-form-field': errorFields.includes('paperGsm') && formSubmited }">
            <label class="block">PAPER GSM <small>(g/m2)</small></label>
            <RangeInput v-model="vModels['paperGsm'].value" :min="0"/>
        </div>
        
        <div class="m-2" :class="{ 'error-form-field': errorFields.includes('wvtr') && formSubmited }">
            <label class="block">WVTR <small>(g/m2*24h)</small></label>
            <input type="number" class="w-full" v-model="vModels['wvtr'].value" min="0"/>
        </div>
        <div class="m-2" :class="{ 'error-form-field': errorFields.includes('wvtrConditions') && formSubmited }">
            <label class="block">WVTR Conditions <small>(°C : g/m²/day)</small></label>
            <!-- <input type="text" class="w-full" v-model="vModels['wvtrConditions'].value"/> -->
             <select class="w-full" v-model="vModels['wvtrConditions'].value">
                <option value="null" selected>select wvtr conditions</option>
                <option v-for="condition in dropdowns.wvtrConditions" :key="condition" :value="condition">{{condition}}</option>
            </select>
        </div>
        <div class="m-2" :class="{ 'error-form-field': errorFields.includes('otr') && formSubmited }">
            <label class="block">OTR <small>(cm3/m2*24h)</small></label>
            <input type="number" class="w-full" v-model="vModels['otr'].value" min="0"/>
        </div>
        <div class="m-2" :class="{ 'error-form-field': errorFields.includes('otrConditions') && formSubmited }">
            <label class="block">OTR Conditions <small>(°C : cc/m²/day)</small></label>
            <!-- <input type="text" class="w-full" v-model="vModels['otrConditions'].value"/> -->
            <select class="w-full" v-model="vModels['otrConditions'].value">
                <option value="null" selected >select otr conditions</option>
                <option v-for="condition in dropdowns.otrConditions" :key="condition" :value="condition">{{condition}}</option>
            </select>
        </div>
        <div class="m-2" :class="{ 'error-form-field': errorFields.includes('heatSeal') && formSubmited }">
            <label class="block">Heat Seal <small>(N/15mm)</small></label>
            <RangeInput v-model="vModels['heatSeal'].value" :min="0" :max="10"/>
        </div>
        <div class="m-2" :class="{ 'error-form-field': errorFields.includes('inkVarnish') && formSubmited }">
            <label class="block">Include Ink & Varnish</label>
            <Switch
                v-model="vModels['inkVarnish'].value"
                :class="vModels['inkVarnish'].value ? 'bg-emerald-700' : 'bg-gray-200'"
                class="relative inline-flex h-7 w-20 items-center rounded-full"
            >
                <span
                :class="vModels['inkVarnish'].value ? 'translate-x-9' : 'translate-x-1'"
                class="inline-block h-5 w-10 transform rounded-full bg-white transition"
                ></span>
            </Switch>
        </div>
        
        
        <div class="m-2 mt-5 col-span-2">
            <div class="flex">
                <button @click="handleAsk" class="ml-auto py-2 px-5 rounded-none  hover:shadow-md active:shadow-inner button cancel" type="button">
                    <PlayIcon class="icon icon-small" />
                </button>
            </div>
        </div>
    </div>
</template>
<script lang="ts" setup>
import { computed,ref} from 'vue';
import { useStore } from 'vuex';
import { PlayIcon } from '@heroicons/vue/24/outline';
import { PRODUCT_BRIEF_FORM_FIELDS } from '@/models/aiModels';
import RangeInput from "@/components/shared/RangeInput.vue";
import { useQuery } from '@vue/apollo-composable';
import { PRODUCTBRIEF_FEED_DATA } from '@/grapql/aiQuery';
import {useDynamicFormModels} from '@/composables/shared/useDynamicFormModels';
import {useFormValidation} from '@/composables/shared/useFormValidation';
import { Switch } from "@headlessui/vue";

const store = useStore();

const vModels = useDynamicFormModels({
    fields:PRODUCT_BRIEF_FORM_FIELDS.formFields, 
    module: "aiModule",
    base: PRODUCT_BRIEF_FORM_FIELDS.graphqlValue,
    mutation:'setProductBriefSingleValue'
});

const formSubmited = ref(false);
const valuesSubmitted = computed(() => store.state.aiModule.ask['productBrief']);

const {isFormValid, errorFields} = useFormValidation(PRODUCT_BRIEF_FORM_FIELDS.formFields,valuesSubmitted.value);

const handleAsk = async () => {
    formSubmited.value = true;
    if(isFormValid.value){
        formSubmited.value=false;
        await store.dispatch("aiModule/askProductBrief");
    }
}

// get data tables values
const dropdowns = ref({'otrConditions':[],'wvtrConditions':[],'aiModels':[]});
const {onResult, onError} = useQuery(PRODUCTBRIEF_FEED_DATA);

onError(async error => {
    console.log(error);
});
onResult(async queryResult => {
    if (queryResult.data) {
        dropdowns.value.otrConditions = queryResult.data.otrConditions || [];
        dropdowns.value.wvtrConditions = queryResult.data.wvtrConditions || [];
        dropdowns.value.aiModels = queryResult.data.aiModels || [];
    }
});

</script>