import { provideApolloClient } from "@vue/apollo-composable";
import apolloClient from "@/utils/apolloClient";
import { useMutation, useSubscription } from "@vue/apollo-composable";
import { AI_SUBSCRIPTION } from "@/grapql/aiSubscription";
import {AI_ASK} from "@/grapql/aiMutation";
import { AI_FORM_TYPE } from "@/models/aiModels";
import {v4 as uuid} from 'uuid';
import { generateProductBriefQuestion,generateGeneralQuestion,generateRegulationsQuestion } from "@/utils/utils";
import _ from 'lodash';
import { IChatQueue } from "@/models/aiInterfaces";
import { MessageType,ContentType } from "@/models/aiModels";

provideApolloClient(apolloClient);

const regulationsDefaultState = ()=>{
    return {
        countries:[],
        application:null,
    }
}
const regulationsModifyDefaultState = ()=>{
    return {
        paperContentPercentage:null,
    }
};
const productBriefDefaultState = ()=>{
    return {
        totalGsm:[],
        paperGsm:[],
        paperContent:[],
        wvtr:null,
        otr:null,
        wvtrConditions:null,
        otrConditions:null,
        heatSeal:[],
        model:null,
        inkVarnish:false,
    }
}

const getDefaultState = () => {
    return {
        isStarted: false,
        ask:{
            subscriptionId:null,
            messageId:null,
            formType:null,
            key:null,
            level:null,
            regulations: regulationsDefaultState(),
            regulationsModify:regulationsModifyDefaultState(),
            productBrief: productBriefDefaultState(),
        },
        lastPulse:null,
        pulsing:null,
        typing:false,
        error:null,
        chatQueue: [],
        currentForm:null,
        currentFlow:null,
        historyPannelOpen:false,
        currentChatId:null,
        typingSpeed:35,
        chatsHistory: [],
        showRetry: false,
        subscription:null,
        isStopped: false, //used when unsubscribed, and then all rendering should be immediately stopped
        isSubscriptionActive: false,
        isCompleted:false,

    }
}
const state= getDefaultState();

const mutations = {
    setSubscriptionId(state,id){
        Object.assign(state, getDefaultState());
        state.ask.subscriptionId = id;
    },
    setMessageId(state,id){
        state.ask.messageId = id;
    },
    setKey(state,key){
        state.ask.key = key
    },
    setSingleValue(state, payload:{field: string , value: Date | number | string | number[] | null}) {
        state.ask[payload.field] = payload.value
    },
    setRegulationsSingleValue(state, payload:{field: string , value: Date | number | string | number[] | null}) {
        state.ask.regulations[payload.field] = payload.value;
        state.ask.formType = AI_FORM_TYPE.Regulations;
    },
    setRegulationsModifySingleValue(state, payload:{field: string , value: Date | number | string | number[] | null}) {
        state.ask.regulationsModify[payload.field] = payload.value;
        state.ask.formType = AI_FORM_TYPE.Regulations;
    },
    setProductBriefSingleValue(state, payload:{field: string , value: Date | number | string | number[] | null}) {
        state.ask.productBrief[payload.field] = Array.isArray(payload.value)?payload.value: payload.value==""?null:payload.value;
        state.ask.formType = AI_FORM_TYPE.ProductBrief;
    },
    setIsInProgress(state,value){
        state.isInProgress = value;
    },
    setError(state,error){
        state.error = error;
    },
    resetStore(state){
        const subscriptionId = state.ask.subscriptionId;
        Object.assign(state, getDefaultState());
        state.ask.subscriptionId = subscriptionId;
    },
    addToChatQueue(state,payload:{messageType:MessageType,messageId:string, content:any,contentType:ContentType}){
        if(payload.messageId){
            const index = state.chatQueue.findIndex(item=> item.messageType === MessageType.ANSWER && item.messageId===payload.messageId);
            if(index !== -1){
                state.chatQueue[index].answer.push({content:payload.content, contentType:payload.contentType});
            }else{
                const newChat: IChatQueue = {messageType:payload.messageType,messageId:payload.messageId,question:null,answer:[],error:null};
                if(payload.messageType === MessageType.QUESTION){
                    newChat.question = {content:payload.content};
                }
                else if(payload.messageType === MessageType.ANSWER){
                    newChat.answer = [{content:payload.content,contentType:payload.contentType}];
                }
                else{
                    newChat.error = {content:payload.content};
                }

                state.chatQueue.push(newChat);
            }
        }else{
            const newChat: IChatQueue = {messageType:payload.messageType,messageId:payload.messageId,question:null,answer:[],error:null};
            if(payload.messageType === MessageType.QUESTION){
                newChat.question = {content:payload.content};
            }
            else if(payload.messageType === MessageType.ANSWER){
                newChat.answer = [{content:payload.content,contentType:payload.contentType}];
            }
            else{
                newChat.error = {content:payload.content};
            }

            state.chatQueue.push(newChat);
        }
        state.chatQueue = [...state.chatQueue];
    },
    setIsStarted(state,value=true){
        state.isStarted=value;
    },
    setPulsing(state,messageId){
        state.pulsing=messageId;
    },
    setLastPulse(state,value){
        state.lastPulse=value;
    },
    setCurrentForm(state,value){
        state.currentForm = value;
    },
    setRegulationsStart(state){
        state.ask.key = "REGULATIONS";
        state.ask.level = "START";
    },
    setRegulationsModify(state){
        state.ask.key = "REGULATIONS";
        state.ask.level = "MODIFY";
    },
    setRegulationsConfirm(state){
        state.ask.key = "REGULATIONS";
        state.ask.level = "CONFIRM";
    },
    setProductBriefStart(state){
        state.ask.key = "PRODUCTBRIEF";
        state.ask.level = "START";
    },
    setIsCompleted(state,value=true){
        state.isCompleted = value;
    },
    setTyping(state,value){
        state.typing = value;
    },
    toggleHistoryPannel(state){
        state.historyPannelOpen = !state.historyPannelOpen;
    },
    setChatsHistory(state,chats){
        state.chatsHistory = chats;
    },
    setChatHistoryMessages(state,payload:{chatId: number, messages}){
       // Find the chat object by chatId
      const chatIndex = state.chatsHistory.findIndex(c => c.id === payload.chatId);
      if (chatIndex !== -1) {
        const chat = state.chatsHistory[chatIndex];
        chat.messages = payload.messages;
        Object.assign(state.chatsHistory[chatIndex], chat);
      }
    },
    setCurrentChatId(state,chatId){
        state.currentChatId = chatId;
    },
    setTypingSpeed(state,speed){
        state.typingSpeed = speed;
    },
    setShowRetry(state,value){
        state.showRetry = value;
    },
    setSubscription(state,subscription){
        state.subscription = subscription;
    },
    setIsStopped(state,value){
        state.isStopped = value;
    },
    setIsSubscriptionActive(state,value){
        state.isSubscriptionActive = value;
    },

}

const actions = {
    stopSubscription({commit}){
        commit('setIsSubscriptionActive',false);
    },
    unsubscribe({state,commit,dispatch}){
        if(state.subscription){
            state.subscription.stop();
            commit('setSubscription',null);
            commit('setPulsing',null);
            commit("setLastPulse",null);
            commit('setShowRetry',true);
            commit('setIsStopped',true);
            console.log('stopped');
            console.log(state.isStopped);

        }
        state.ask.subscriptionId = uuid();
        // dispatch('subscribe');
    },
    resubscribe({state}){
        if(state.subscription.start){
            state.subscription.start();
            console.log('resubscribed');
        }
    },
    async subscribe({state,commit}){

        const { onResult, onError } = useSubscription(AI_SUBSCRIPTION,{subscriptionId: state.ask.subscriptionId},()=>({
            enabled: state.isSubscriptionActive
        }));

        onResult((result) => {
            const response = result.data?.aiResponse;
            if(response.subscriptionId==state.ask.subscriptionId){

                // handle pulsing
                handlePulsing(response);
                
                // 1. handle error case
                if(response.stage=="ERROR"){
                    handleErrorCase(response);
                }
                // 1.1 handle stop case
                else if(response.step=="STOP"){
                    handleStopCase(response);
                }
                // 2. else continue rendering
                else{
                    // 2.1 handle last pulse if analyze or check
                    if(response.step=="ANALYZE" || response.step=="CHECK"){
                        commit("setLastPulse",response.content);
                    }
                    // any thing else is response, but set lastPulse as null
                    else{
                        // set lastPulse to null, to not show on screen
                        commit("setLastPulse",null);

                        // 2.1 regulations stage
                        if(response.stage =="REGULATIONS"){
                            // if step is confirm, show confirmation form
                            if(response.step =="CONFIRM"){
                                commit('setCurrentForm',"regulationsConfirm");
                            }
                            if(response.step == "NEXT"){
                                commit('setCurrentForm','regulationsNext');
                            }
                            else{
                                handleResponse(response);
                            }
                        }
                        else{
                            handleResponse(response);
                        }
                    }
                    
                }
            }
        })
        onError((error) => {
            console.log('error in subscription',error);
            console.log(error)
            commit('setPulsing',null);
            commit("setIsSubscriptionActive",false);
        })

        // save the subscription
        commit('setSubscription',{stop});

        const handleErrorCase=(response)=>{
            commit('addToChatQueue',{messageType:MessageType.ERROR,messageId:response?.messageId,content:response.content,contentType:ContentType.TEXT});
            commit('setPulsing',null);
            commit("setLastPulse",null);
            commit('setShowRetry',true);
            commit("setIsSubscriptionActive",false);

        }

        // handle stop case, when step is stop
        const handleStopCase= (response)=>{
            commit('setPulsing',null);
            commit("setLastPulse",null);
            commit("setIsCompleted");

        }
        const handleResponse = (response)=>{
            commit('addToChatQueue',{messageType:MessageType.ANSWER,messageId:response?.messageId,content:response.content,contentType:response.contentType});
        }
        // handle pulsing, set it to null when
        // 1. stage = error
        // 2. step = stop
        // 3. step = comfirm
        const handlePulsing=(response)=>{
            if(response.stage == 'ERROR' || ['STOP','CONFIRM','NEXT'].includes(response.step) )
            {
                commit('setPulsing',null);
            }
        }


    },

    async  ask({state, commit, dispatch},input) {

        commit("setIsSubscriptionActive",true);

        // set new message_id on every ask
        // commit("setMessageId",uuid());
        input.messageId = uuid();

        // set isStarted to True
        commit("setIsStarted");
        commit("setPulsing",input.messageId);

        commit('setShowRetry',false);
        
        commit('setIsStopped',false);

        // set currentForm to null
        commit('setCurrentForm',null);

        // set lastPulse to blank space so that pulsing can be shown
        commit("setLastPulse",JSON.stringify(' '));


        // commit("setBeforeAsk");
        
        return new Promise<void>((resolve,reject) => {

            const { mutate: ask, onDone, onError } = useMutation(AI_ASK);
            ask({input: input});

            console.log('callleedd')
            onDone(() => { 
                // commit("setProcessInProgress", false);
                

                resolve();
            });
            onError((error) => {
                // commit("setProcessInProgress", false);
                commit("setLastPulse",null);
                commit('setError', error.message);
                reject(error);
            });

            
        })

    },
    // handle regulations Start
    async askRegulations({state, commit, dispatch}){
        commit('setRegulationsStart');
        const input = _.cloneDeep(state.ask);
        input.regulationsModify = null;
        input.productBrief = null;

        // now add the regulations to chat
        commit('addToChatQueue',{messageType:MessageType.QUESTION,messageId:null,content:generateRegulationsQuestion(state.ask.regulations),contentType:ContentType.TEXT});

        await dispatch('ask',input)
        .then(function () {
            console.log('then');
        }).catch(()=>{
            console.log('ask regulations error caught');
        });
            
    },

    async askRegulationsConfirm({state, commit, dispatch}){
        commit("setRegulationsConfirm");
        const input = _.cloneDeep(state.ask);
        input.regulationsModify = null;
        input.productBrief = null;

        await dispatch('ask',input)
        .then(function () {
            console.log('then');
        }).catch(()=>{
            console.log('ask regulations confirm error caught');
        });

    },
    async askRegulationsModify({state, commit, dispatch}){
        commit('setRegulationsModify');
        const input = _.cloneDeep(state.ask);
        input.productBrief = null;

        // now add the regulations modify to chat
        commit('addToChatQueue',{messageType:MessageType.QUESTION,messageId:null,content:generateGeneralQuestion(state.ask.regulationsModify),contentType:ContentType.TEXT});

        await dispatch('ask',input)
        .then(function () {
            console.log('then');
        }).catch(()=>{
            console.log('ask regulations modify error caught');
        });
    },
    // handle productBrief Start
    async askProductBrief({state, commit, dispatch}){
        commit('setProductBriefStart');
        const input = _.cloneDeep(state.ask);
        input.regulations = null;
        input.regulationsModify=null;

        //not add productBrief to chat
        commit('addToChatQueue',{messageType:MessageType.QUESTION,messageId:null,content:generateProductBriefQuestion(state.ask.productBrief),contentType:ContentType.TEXT});


        await dispatch('ask',input)
        .then(function () {
            console.log('then');
        }).catch(()=>{
            console.log('ask productBrief error caught');
        });
    },

    
}
const getters = {
    getIsStarted(state){
        return state.isStarted;
    },
    getAnswer(state){
        return state.answer;
    },
    getIsPulsing(state){
        return state.pulsing;
    },
    getIsTyping(state){
        return state.typing;
    },
    getIsCompleted(state){
        return state.isCompleted && !state.typing && !state.pulsing;
    },
    getIsPulsingOrTyping(state){
        return state.pulsing || state.typing;
    },
    getHistoryPannelStatus(state){
        return state.historyPannelOpen;
    },
    // get all chats history
    getChatsHistory(state,){
        return state.chatsHistory;
    },
    getCurrentChatMessages(state){
        console.log('################################')

        console.log(state.currentChatId);
        console.log(state.chats);
        console.log(state.chats[state.currentChatId]);
        console.log('################################')
        return state.chats[state.currentChatId]?.messages;
    },
    getShowRetry(state){
        return state.showRetry;
    },
    getIsSubscriptionActive(state){
        return state.isSubscriptionActive;
    },
}

export default {
    namespaced: true,
    state, 
    mutations,
    getters,
    actions
}
