<template>
    <div class="pt-20 pb-20 ml-10 mr-20 flex" id="chat-container" ref="chatContainer">
        <!-- show previous chats -->
        <div v-if="chatsHistory" class="grid grid-cols-1 gap-4">
            <div>
                <div v-for="(item,index) in chatsHistory.messages" :key="index" class="chat-message">
                    <AIChatQuestion v-if="item.messageType == MessageType.QUESTION" :question="item.question" />
                    <AIChatAnswer v-else-if="item.messageType == MessageType.ANSWER" :answer="item.answer" :messageId="item.messageId" :isHistory="true"/>
                    <AIChatError v-else-if="item.messageType == MessageType.ERROR" :error="item.error"/>
                </div>
            </div>
        </div>  
        <div class="grid grid-cols-1 gap-4">
            <div>
                <div v-for="(item,index) in chatQueue" :key="index" class="chat-message">
                        <AIChatQuestion v-if="item.messageType === MessageType.QUESTION" :question="item.question" />
                        <AIChatAnswer v-else-if="item.messageType === MessageType.ANSWER" :answer="item.answer" :messageId="item.messageId"/>
                        <AIChatError v-else-if="item.messageType === MessageType.ERROR" :error="item.error"/>
                </div>
                
                <RegulationsConfirm v-if="currentForm=='regulationsConfirm' && !isPulsingOrTyping" />
                <AIFlowBoxes v-if="currentForm=='regulationsNext' && !isPulsingOrTyping"/>

                <LastPulse />
            </div>
            <AIEnd v-if="showEnd" />
       </div>

       <AIError v-if="showError"/>

       <AIFlowBoxes v-if="showRetry" :showRegulations="true" :showProductBrief="true" :showSmallIcons="true"/>

       <!-- used for scrolling -->
       <!-- <div ref="bottomOfChat" class="py-10">&nbsp;</div> -->
        <!-- <AIChatSandwichRenderer :content="a" /> -->
   </div>
</template>
<script lang="ts" setup>
import { ref,computed, defineProps,watch,onUpdated } from 'vue';
import { useStore } from 'vuex';
import AIChatQuestion from "@/components/ai/AIChatQuestion.vue";
import AIChatAnswer from "@/components/ai/AIChatAnswer.vue";
import AIChatError from '@/components/ai/AIChatError.vue';
import LastPulse from './LastPulse.vue';
import RegulationsConfirm from "@/components/ai/RegulationsConfirm.vue";
import AIFlowBoxes from "@/components/ai/AIFlowBoxes.vue";
import AIEnd from "@/components/ai/AIEnd.vue";
import AIError from "@/components/ai/AIError.vue";
import {useQuery} from '@vue/apollo-composable';
import {useEdgesToMessages} from "@/composables/aiComposables";
import {CHAT_MESSAGES} from '@/grapql/aiQuery';
import { IChatHistroy,IChatQueue } from '@/models/aiInterfaces';
import { MessageType } from '@/models/aiModels';

const props = defineProps(['currentChatId']);

const store = useStore();

const isPulsingOrTyping = computed(()=> store.getters["aiModule/getIsPulsingOrTyping"]);

// get currentForm
const currentForm = computed(()=>store.state.aiModule.currentForm);

// get error
const showError = computed(()=> !!store.state.aiModule.error);

// get and check if is completed
const showEnd = computed(()=> store.getters["aiModule/getIsCompleted"]);

// Refs for the chat container and the bottom of the chat
const chatContainer = ref(null);
// const bottomOfChat = ref(null);

// chat history
const chatsHistory = ref<IChatHistroy>(null);

// show forms for retrying
const showRetry = computed(()=> store.getters["aiModule/getShowRetry"]);

// get chat
const chat = computed(()=> {
    return store.state.aiModule.chat;
});

// get chat Queue
const chatQueue = computed<IChatQueue[]>(()=> {
    return store.state.aiModule.chatQueue;
});

watch(() => props.currentChatId, (newVal, oldVal) => {
    if (newVal !== oldVal) {
        //get chat history
        const {onResult , error} = useQuery(CHAT_MESSAGES, {chatId: props.currentChatId});
        if (error) {
        console.log(error);
        }
        onResult(async queryResult => {
            if (queryResult.data && queryResult?.data?.chat?.messages) {
                store.commit('aiModule/setChatHistoryMessages', {chatId:props.currentChatId, messages: useEdgesToMessages(queryResult.data.chat.messages.edges)});
            }
            chatsHistory.value = store.state.aiModule.chatsHistory.find(chat => chat.id === props.currentChatId);
        });
    }
});

onUpdated(()=>{
    window.scrollTo(0,document.body.scrollHeight);
});
</script>
<style scoped>
#chat-container{
    overflow-y: auto;
    display: block;
}


</style>